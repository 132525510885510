const constants = {
    apiUrl: 'https://buymeua.shop/',
    telegramBot: 'BuyMeUA_bot',
    pusher: {
        PUSHER_APP_ID: 1506238,
        PUSHER_APP_KEY: '58990fd62b04eb710dbc',
        PUSHER_APP_SECRET: 'f7aa550b3851859fd729',
        PUSHER_APP_CLUSTER: 'eu',
        PUSHER_APP_ENCRYPTED: true,
    },
    // Add other keys you want here
};

export default constants;
