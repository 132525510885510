import type { FC } from 'react'
import { Title, Card, Button, Spiner } from 'components'
import styles from './Novelty.module.scss'
import { Link } from 'react-router-dom'

const Novelty: FC<any> = ({ data }) => {
  const subtitle = `Інтернет-магазин BuyMe — це доступні ціни та широкий асортимент товарів зі щоденним оновленням`

  const renderItems = data?.categoryProducts
    ? data.categoryProducts.slice(0, 20).map((element: any, index: number) => {
        return (
          <div key={element.id}>
            <Card
              key={index}
              id={element.id}
              image={element.thumb}
              title={element.name}
              rate={element.special}
              reviwers={element.reviwers}
              price={element.price}
              saved={element.in_wishlist}
            />
          </div>
        )
      })
    : null

  return (
    <div className={styles.wrapper}>
      <Title title='Новинки' subtitle={subtitle} />

      {data.loadingcategoryProducts && data?.categoryProducts.length == 0 ? (
        <Spiner />
      ) : (
        <>
          <div className={styles.wrapper__content}>{renderItems}</div>
          <Link to='/app/categories'>
            <Button className={styles.wrapper__button}>Дивитися усі</Button>
          </Link>
        </>
      )}
    </div>
  )
}

export default Novelty
