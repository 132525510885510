import { ArrowRIghtIcon, SupportSvg } from 'assets'
import React from 'react'

interface Manager {
  username: string
  description: string
}

const ManagersList: React.FC = () => {
  const openTg = (username: string) => {
    window.open(`tg://resolve?domain=${username}`, '_blank')
  }

  const managers = [
    {
      username: 'BuyMePayment',
      description: 'Проблеми з оплатою замовлення Обмін/повернення Переадресація замовлень (зміна даних)',
    },
    {
      username: 'Buyme_photo_service',
      description: 'Додаткові фото товару Заміри товару Менеджер на складі з 10:00 до 18:00',
    },
    {
      username: 'BuyMe_cancellation_delivery',
      description: 'Затримки в відправленні Відміна відправлень',
    },
    {
      username: 'buy_me_ua',
      description: 'комерційні пропозиції Питання щодо роботи з застосунком Поповнення товарів ( новинки )',
    },
    {
      username: 'kropinova',
      description: 'Затримки виплат Некорректно підрахований баланс',
    },
  ]

  return (
<div style={{ display: 'flex', flexDirection: 'column', gap: 4, maxHeight: 400, paddingBottom: 0, overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
      {managers.map((manager, index) => (
        <div
          key={index}
          style={{
            cursor: 'pointer',
            padding: '20px 14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            borderRadius: 8,
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
            marginBottom: 8,
          }}
          onClick={() => openTg(manager.username)}
        >
          <div style={{ display: 'flex' ,alignItems: 'flex-start', gap: 8 }}>
      
            <SupportSvg className=' mt-1' color="#ab0000"  />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4, maxWidth: '90%' }}>
              <span style={{ fontSize: 16 }}>{'@' + manager.username}</span>
              <span style={{fontSize:14}}>{manager.description}</span>
            </div>
          </div>
          <div className=' min-w-5'>
          <ArrowRIghtIcon width={20} height={20} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default ManagersList
