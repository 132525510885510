// MultiLevelMenu.js
import { ArrowRIghtIcon, NotFoundImage } from 'assets'
import { useAppDispatch, useAppSelector } from 'hooks/useTypedSelector'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchCategoryTreeData } from 'store/features/Category/categorySlice'
import styles from './Menu.module.scss'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { useRef } from 'react'

const MultiLevelMenu = ({ isOpen = false, isAuthenticated, userEmail, logout }: any) => {
  const [showChildren, setShowChildren] = useState(false)
  const [renderItem2, setRenderItem2] = useState<any>()
  const [showChildren2, setShowChildren2] = useState<boolean>(false)
  const [showBlog, setShowBlog] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const categoryTreeData = useAppSelector(state => state?.categoryTree?.data)
  const catalogItems = categoryTreeData ? categoryTreeData : []
  const [activeCategory, setActiveCategory] = useState<string | null>(null)
  const nodeRef = useRef(null)
  const nodeRef2 = useRef(null)

  useEffect(() => {
    dispatch(fetchCategoryTreeData())
  }, [])

  const renderCatalogItem = catalogItems.map((element: any, index: number) => (
    <a
      onClick={() => {
        if (element?.childrens.length > 0) {
          setShowChildren2(true)
        }
      }}
      style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}
      href={element?.childrens.length > 0 ? '#' : `/app/categories?category_id=${element.id}`}
      key={index}
      className={classNames(styles.wrapper__dropdown__left__item, {
        [styles.wrapper__dropdown__left__item__active]: activeCategory === element.id,
      })}
      onMouseOver={() => handleMouseOverCategory(element)}
    >
      <div>
        <img src={element.icon || NotFoundImage} />
        <p className={styles.wrapper__dropdown__left__item__text}>{element.name}</p>
      </div>
      {element?.childrens.length > 0 ? <ArrowRIghtIcon /> : ''}
    </a>
  ))

  const handleMouseOverCategory = (category: any) => {
    setRenderItem2(category.childrens)
    setActiveCategory(category.id)
  }

  return (
    true && (
      <>
        <CSSTransition in={isOpen} nodeRef={nodeRef} timeout={300} classNames='slidenavbar' unmountOnExit>
          <div ref={nodeRef} className='bg-white fixed left-0 z-[9999] h-screen top-20 w-full'>
            {!isAuthenticated ? (
              <div className='wrapper'>
                <div className='pages pt-3 px-2'>
                  <Link
                    onClick={() => setShowChildren(true)}
                    className='  font-normal justify-between text-sm flex gap-2 items-center'
                    to='#'
                  >
                    {' '}
                    Каталог
                    <ArrowRIghtIcon />
                  </Link>
                </div>
              </div>
            ) : (
              <div className='wrapper pt-3 px-2'>
                {/* <a href='/profile' className='profile__user flex justify-between items-center mb-6 '>
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <ProfileIcon />
                <div>
                  <h1 style={{ color: 'black', fontWeight: 600, fontSize: '14px' }}>Мій профіль</h1>
                  <p style={{ color: '#4C5563', fontWeight: 400, fontSize: '14px' }}> {userEmail}</p>
                </div>
              </div>
              <ArrowRightNavIcon />
            </a> */}

                <div className='pages mb-4 '>
                  <li
                    onClick={() => setShowChildren(true)}
                    className=' cursor-pointer  font-normal justify-between text-sm flex gap-2 items-center'
                  >
                    {' '}
                    Каталог
                    <ArrowRIghtIcon />
                  </li>
                </div>
              </div>
            )}
          </div>
        </CSSTransition>

        <CSSTransition
          in={showChildren && isOpen}
          nodeRef={nodeRef2}
          timeout={300}
          classNames='slidenavbar'
          unmountOnExit
        >
          <div ref={nodeRef2} className='menu-item h-screen overflow-y-scroll fixed w-full top-20 z-[9999999] bg-white '>
            <button
              onClick={() => {
                setShowChildren(false)
                setShowChildren2(false)
              }}
              className=' py-3 px-2 bg-[#1a1a1a33] w-full flex gap-2 items-center'
            >
              <div className='flex gap-2 items-center'>
                <ArrowRIghtIcon className=' rotate-180' />
                назад
              </div>
            </button>
            <div className='pt-3 px-2 flex flex-col gap-2'> {renderCatalogItem}</div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={showChildren2 && isOpen && renderItem2}
          nodeRef={nodeRef2}
          timeout={300}
          classNames='slidenavbar'
          unmountOnExit
        >
          <div ref={nodeRef2} className=' fixed w-full h-screen  overflow-y-scroll  left-0 menu-item top-20 bg-white z-[9999999999]'>
            <button
              onClick={() => {
                setShowChildren2(false)
              }}
              className=' py-3 px-2 bg-[#1a1a1a33] w-full flex gap-2 items-center'
            >
              <div className='flex gap-2 items-center'>
                <ArrowRIghtIcon className=' rotate-180' />
                назад
              </div>
            </button>
            {renderItem2?.map((data: any, index: number) => (
              <div
                className=' py-3 px-2'
                style={{ textTransform: 'capitalize', display: 'flex', flexDirection: 'column', gap: '10px' }}
                key={index}
              >
                <a
                  href={`/app/categories?category_id=${data.id}`}
                  style={{ cursor: 'pointer', color: '#ab0000', fontWeight: 400, fontSize: '16px' }}
                >
                  {data.name}
                </a>
                {data.childrens?.map((child: any, index: number) => (
                  <a
                    href={`/app/categories?category_id=${child.id}`}
                    style={{ cursor: 'pointer', color: '#4c5563', fontWeight: 300, fontSize: '16px' }}
                    key={index}
                  >
                    {child.name}
                  </a>
                ))}
              </div>
            ))}
          </div>
        </CSSTransition>
      </>
    )
  )
}

export default MultiLevelMenu
