import { FC, useEffect, useState } from 'react'
import styles from './ToOrder.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { Button, Checkbox, Modal } from 'components/atom'
import InputAuth from 'components/atom/Input/Auth'
import { PaintIcon } from 'assets'
import { addGiftPackage, getCartInfo } from 'config/model/cart'

const ToOrder: FC<any> = ({ data, onOrderButtonClick, disable, refreshCart }) => {
  const location: any = useLocation()
  const isPlacing = location.pathname === '/placing'
  const [infoTitle, setInfoTitle] = useState<string>('')
  const [infoText, setInfoText] = useState<string>('')
  const [showCartInfo, setShowCartInfo] = useState(false)

  const [giftPackage, setGiftPackage] = useState(data.gift_package)

  const toggleSwitchGift = async () => {
    await addGiftPackage(!data.gift_package)
    refreshCart()
  }

  useEffect(() => {
    getCartInfo()
      .then((data: any) => {
        if (data?.data?.info?.description) {
          setInfoTitle(data.data.info.title)
          setInfoText(data.data.info.description)
        }
      })
      .catch((e: any) => console.log(e))
  }, [data])

  const handleClose = () => {
    setShowCartInfo(false)
  }

  return (
    <div>
      {['error_connect_tg', 'error_cart_stock'].includes(data.message_code) ? (
        <div className={styles.wrapper}>
          <p className=' text-center text-[#ab0000]'>{data.message}</p>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.wrapper__prices}>
            <div>
              <p>Вартість замовлення</p>
              <span>{data?.total_format}</span>
            </div>
            <div className={styles.wrapper__prices__footer}>
              <p>До оплати без доставки</p>
              <span>{data?.total_format}</span>
            </div>
          </div>
          <div className={styles.wrapper__buttons}>
            {isPlacing ? (
              <div>
                {' '}
                <Button onClick={onOrderButtonClick} className={styles.wrapper__buttons__btn_1}>
                  Замовити
                </Button>
              </div>
            ) : (
              <>
                {' '}
                <Link to='/placing'>
                  <Button>Оформити замовлення</Button>
                </Link>
                {/* <Button onClick={()=>setShowOrder(true)} className={styles.wrapper__buttons__btn_2}>Замовити в один клік</Button> */}
              </>
            )}
            {/* <p onClick={() => setShowCartInfo(true)} className=' underline text-center mt-2 text-sm cursor-pointer'>
              Ознайомитись з умовами доставки
            </p> */}
          </div>

          <Modal show={showCartInfo} onClose={handleClose}>
            <div dangerouslySetInnerHTML={{ __html: infoTitle }} />

            <div dangerouslySetInnerHTML={{ __html: infoText }} />
          </Modal>
        </div>
      )}

      {/* {!['error_connect_tg', 'error_cart_stock'].includes(data.message_code) && (
        <div className=' center mt-3' onClick={() => toggleSwitchGift()}>
          <Checkbox checked={data.gift_package} />

          <h3>Подарункова упаковка (+50 грн)</h3>
        </div>
      )} */}
    </div>
  )
}

export default ToOrder
