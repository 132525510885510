import { type FC, Suspense, useMemo, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { HelmetLayout, RouteLoader } from 'components'
import routesList from './routes'

export const RoutesWrapper: FC = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const renderRoutes = useMemo(
    () =>
      routesList.map(({ element: Element, path, title }) => (
        <Route
          key={path}
          element={
            <Suspense
              fallback={
                <div className='absolute z-50 top-0 left-0 right-0 bottom-0 center'>
                  <RouteLoader />
                </div>
              }
            >
              <HelmetLayout title={title}>
                <Element />
              </HelmetLayout>
            </Suspense>
          }
          path={path}
        />
      )),

    []
  )

  return <Routes>{renderRoutes}</Routes>
}
