import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api/axiosConfig'
import { TCategory } from './type'

const initialState: TCategory = {
  data: [],
  categoryProducts: [],
  dataChildren: [],
  loading: false,
  loadingcategoryProducts:false,
  error: null,
}

export const fetchCategoryProducts = createAsyncThunk('category/fetchCategoryProducts', async ({ id, page = 1 }: any) => {
  try {
    const response = await api.get(`/v1/categories/${id}/products?page=1`)

    return response.data
  } catch (err) {
    throw Error('Failed to get category data')
  }
})

export const fetchCategoryData = createAsyncThunk('category/fetchCategoryData', async () => {
  try {
    const response = await api.get('/v1/categories/root-parents')

    return response.data
  } catch (err) {
    throw Error('Failed to get category data')
  }
})

export const fetchCategoryTreeData = createAsyncThunk('category/fetchCategoryTreeData', async () => {
  try {
    const response = await api.get('/v1/categories/tree')

    return response.data
  } catch (err) {
    throw Error('Failed to get category tree data')
  }
})

export const fetchCategoryChildren = createAsyncThunk('category/fetchCategoryChildren', async (id: number) => {
  try {
    const response = await api.get(`/v1/categories/${id}/children`)

    return response.data
  } catch (err) {
    throw Error('Failed to get category tree data')
  }
})

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCategoryData.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchCategoryData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(fetchCategoryData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'failed to fetch category'
      })
      .addCase(fetchCategoryProducts.pending, state => {
        state.loadingcategoryProducts = true
        state.error = null
      })
      .addCase(fetchCategoryProducts.fulfilled, (state, action) => {
        state.loadingcategoryProducts = false
        state.categoryProducts = action.payload
      })
      .addCase(fetchCategoryProducts.rejected, (state, action) => {
        state.loadingcategoryProducts = false
        state.error = action.error.message || 'failed to fetch category'
      })
  },
})

const categoryTreeSlice = createSlice({
  name: 'category tree',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCategoryTreeData.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchCategoryTreeData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(fetchCategoryTreeData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'failed to fetch category tree'
      })
      .addCase(fetchCategoryChildren.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchCategoryChildren.fulfilled, (state, action) => {
        state.loading = false
        state.dataChildren = action.payload
      })
      .addCase(fetchCategoryChildren.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'failed to fetch category tree'
      })
  },
})

export { categorySlice, categoryTreeSlice }
