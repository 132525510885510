import { type FC, useEffect, useState } from 'react'
import { Chat, Modal } from 'components'
import { FacebookIcon, InstagramIcon, LogoIcon, PhoneIcon } from 'assets'
import styles from './Footer.module.scss'
import { useLocation } from 'react-router'
import { useResponsive } from 'hooks'
import ManagersList from 'components/molecules/Account/Contacts'

const Footer: FC = () => {
  const location = useLocation()
  const { isTablet } = useResponsive()
  const [isPathNameHome, setIsPathNameHome] = useState<string>()
  const [showContacts, setShowContacts] = useState<boolean>(false)

  useEffect(() => {
    setIsPathNameHome(location.pathname)
  }, [location])

  return (
    <>
      <footer className='w-full'>
        {isPathNameHome !== '/' && !isTablet && (
          <div className='w-full mb-4 pt-[120px]'>
            <Chat openContacts={() => setShowContacts(true)} />
          </div>
        )}
        <div className='flex items-center justify-between w-full pt-8 px-[120px] pb-[55px] bg-[#1a1a1a] gap-4 max-[1024px]:flex-col max-[1024px]:justify-center max-[1024px]:items-center max-[1024px]:gap-8'>
          <LogoIcon />
          <div className='flex items-center gap-10 max-[1024px]:center max-[1024px]:gap-4 max-[1024px]:flex-col'>
            <p className='cursor-pointer text-[#959595] whitespace-nowrap leading-[100%]'>Про нас</p>
            <p className='cursor-pointer text-[#959595] whitespace-nowrap leading-[100%]'>Контакти</p>
            <p className='cursor-pointer text-[#959595] whitespace-nowrap leading-[100%]'>Блог</p>
            <p className='cursor-pointer text-[#959595] whitespace-nowrap leading-[100%]'>Політика конфіденційності</p>
          </div>
          <div className={styles.wrapper__social}>
            <div className={styles.wrapper__social__icons}>
              <InstagramIcon />
              <FacebookIcon />
            </div>
            <div className={styles.wrapper__social__phone}>
              <PhoneIcon />
              <p className={styles.wrapper__social__phone__number}>0 800 32 32 44</p>
            </div>
          </div>
        </div>
      </footer>
      <Modal show={showContacts} onClose={() => setShowContacts(false)}>
        <ManagersList />
      </Modal>
    </>
  )
}

export default Footer
