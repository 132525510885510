import { useEffect, type FC } from 'react'
import { RoutesWrapper } from 'router'
import { Footer, Header, MobileNavBar } from 'components'
import { useResponsive } from 'hooks'
import { useAppDispatch } from 'hooks/useTypedSelector'
import { fetchBasketList } from 'store/features/Basket/basketSlice'
import { fetchWatchList } from 'store/features/Watchlist/watchListSlice'

const PageLayout: FC = () => {
  const { isTablet } = useResponsive()

  const dispatch = useAppDispatch()

  useEffect(() => {
    const descriptionMetaTag = document.querySelector('meta[name="description"]')
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute('content', `Интернет магазин BuymeUa `)
    }
  }, [])

  useEffect(()=>{
    dispatch(fetchBasketList())
    dispatch(fetchWatchList())
  },[dispatch])

  return (
    <div className="w-full h-screen flex flex-col justify-between max-[876px]:mt-20">
      <Header />
      <main>
        <RoutesWrapper />
      </main>
      <Footer />
      {isTablet && <MobileNavBar />}
    </div>
  )
}

export default PageLayout
