import { lazy } from 'react'

import { ERoutePaths, TRoutePageType } from './types'
import { CUSTOMER_GROUPS } from 'config/helper'

const Home = lazy(() => import('pages/Home'))
const Login = lazy(() => import('pages/Auth/Login'))
const Signup = lazy(() => import('pages/Auth/Signup'))
const Changepassword = lazy(() => import('pages/Auth/Changepassword'))
const Basket = lazy(() => import('pages/Basket'))
const Saved = lazy(() => import('pages/Saved'))
const Placing = lazy(() => import('pages/Placing'))
const Product = lazy(() => import('pages/Product'))
const CategoriesPage = lazy(() => import('pages/CategoriesPage'))
const SubCategoriesPage = lazy(() => import('pages/SubCategories'))
const MainCategoriesPage = lazy(() => import('pages/MainCategory'))
const Profile = lazy(() => import('pages/Profile'))
const Promotions = lazy(()=> import('pages/Promotions'))
const Account = lazy(()=> import('pages/Account'))
const Orders = lazy(()=> import('pages/Orders'))
const Balance = lazy(()=> import('pages/Balance'))

const userData = localStorage.getItem('userData')
const isAuthenticated = userData !== null || undefined
const user = userData ? JSON.parse(userData || '') : null

const showBalancePage = [
  CUSTOMER_GROUPS.CUSTOMER_GROUP_PARTNER,
  CUSTOMER_GROUPS.CUSTOMER_GROUP_WHOLESALE,
  CUSTOMER_GROUPS.CUSTOMER_GROUP_DROPSHIPPING,
  CUSTOMER_GROUPS.CUSTOMER_GROUP_MANAGER,
  CUSTOMER_GROUPS.CUSTOMER_GROUP_DROP_MANAGER,
  CUSTOMER_GROUPS.CUSTOMER_GROUP_PROVIDER,
].includes(user?.data?.customer_group_id);

const routesList: TRoutePageType[] = [
  {
    element: Home,
    path: ERoutePaths.Home,
    title: 'Home',
  },
  {
    element: isAuthenticated ? Profile : Login,
    path: ERoutePaths.Login,
    title: isAuthenticated ? 'Profile Page' : 'Login page',
  },
  {
    element: isAuthenticated ? Profile : Signup,
    path: ERoutePaths.Signup,
    title: isAuthenticated ? 'Profile Page' : 'Signup page',
  },
  {
    element: Basket,
    path: ERoutePaths.Basket,
    title: 'Basket page',
  },
  {
    element: Placing,
    path: ERoutePaths.Placing,
    title: 'Placing page',
  },
  {
    element: Saved,
    path: ERoutePaths.Saved,
    title: 'Saved items',
  },
  {
    element: Product,
    path: ERoutePaths.Product,
    title: 'Product Page',
  },
  {
    element: CategoriesPage,
    path: ERoutePaths.CategoriesPage,
    title: 'Categories Page',
  },
  {
    element: SubCategoriesPage,
    path: ERoutePaths.SubCategoriesPage,
    title: 'SubCategories Page',
  },
  {
    element: MainCategoriesPage,
    path: ERoutePaths.MainCategoriesPage,
    title: 'Categories Page',
  },
  {
    element: Profile,
    path: ERoutePaths.Profile,
    title: 'Profile Page',
  },
  {
    element: Promotions,
    path: ERoutePaths.Promotions,
    title: 'Promotions Page',
  },
  {
    element: Account,
    path: ERoutePaths.Account,
    title: 'Account Page',
  },
  {
    element: isAuthenticated ? Orders : Login,
    path: `${ERoutePaths.Account}/orders`,
    title: 'Orders',
  },
  {
    element: showBalancePage ? Balance : Account,
    path: `${ERoutePaths.Account}/balance`,
    title: 'Balance',
  },
  {
    element: isAuthenticated ? Changepassword : Login,
    path: `${ERoutePaths.Account}/changepassword`,
    title: 'Chnage password page',
  },
]

export default routesList
