import { useEffect, useRef, type FC } from 'react'
import styles from './Modal.module.scss'
import { ModalProps } from './type'
import { CSSTransition } from 'react-transition-group'
import { CloseIcon } from 'assets'

const Modal: FC<ModalProps> = ({ show, children, onClose }) => {
  const nodeRef = useRef(null)

  useEffect(() => {
    const body = document.body
    if (show) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = ''
    }

    return () => {
      body.style.overflow = ''
    }
  }, [show])

  return (
    <CSSTransition in={show} nodeRef={nodeRef} timeout={300} classNames='popup' unmountOnExit>
      <div className={styles.modal}>
        <CSSTransition in={show} nodeRef={nodeRef} timeout={300} classNames='popup' unmountOnExit>
          <div ref={nodeRef} className={styles.modal__block}>
            <button className='absolute right-4 top-4 mt-[11px]' onClick={onClose}>
              <CloseIcon />
            </button>
            {children}
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  )
}

export default Modal
