

import api from "api/axiosConfig";
import { CART, INFORMATION, USER } from "config/edpoint";


export const getCartInfo: any = async () => {
    const { ALL } = INFORMATION;
    return await api.get(ALL + '/cart');
};


export const addGiftPackage: any = async (gift: boolean) => {
    const { LIST } = CART;

    return await api.put(LIST + '/add-gift-package', {
        include_gift_package: gift,
    });
};