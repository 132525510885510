import styles from './MobileNavBar.module.scss'
import { NavLink } from 'react-router-dom'
import { ERoutePaths } from 'router/types'
import classNames from 'classnames'
import { HeartStrokeIcon, HomeIcon, ListIcon, ShopCartIcon, UserIcon } from 'assets'
import { useAppSelector } from 'hooks/useTypedSelector'

const MobileNavBar = () => {
  const basketQuality = useAppSelector(state => state?.basketSlice?.data?.data?.length)
  const watchlistQuality = useAppSelector(state => state?.watchlistSLice?.data?.length)

  return (
    <div className={styles.wrapper}>
      <NavLink
        to={ERoutePaths.Home}
        className={({ isActive }) =>
          isActive ? classNames(styles.wrapper__item, styles.wrapper__item__active) : styles.wrapper__item
        }
      >
        <HomeIcon />

        <p>Головна</p>
      </NavLink>

      <NavLink
        to={ERoutePaths.CategoriesPage}
        className={({ isActive }) =>
          isActive ? classNames(styles.wrapper__item, styles.wrapper__item__active) : styles.wrapper__item
        }
      >
        <ListIcon />

        <p>Каталог</p>
      </NavLink>

      <NavLink
        to={ERoutePaths.Basket}
        className={({ isActive }) =>
          isActive ? classNames(styles.wrapper__item, styles.wrapper__item__active) : styles.wrapper__item
        }
      >
        <ShopCartIcon />

        <p>Кошик</p>
        {basketQuality !== 0 && basketQuality !== undefined && (
          <div className='absolute center top-[-8px] right-0 size-[17px] rounded-full bg-black text-white'>
            <p>{basketQuality}</p>
          </div>
        )}
      </NavLink>

      <NavLink
        to={ERoutePaths.Saved}
        className={({ isActive }) =>
          isActive ? classNames(styles.wrapper__item, styles.wrapper__item__active) : styles.wrapper__item
        }
      >
        <HeartStrokeIcon />

        <p>Бажане</p>
        {watchlistQuality !== 0 && watchlistQuality !== undefined && (
          <div className='absolute center top-[-8px] right-0 size-[17px] rounded-full bg-black text-white'>
            <p>{watchlistQuality}</p>
          </div>
        )}
      </NavLink>

      <NavLink
        to={'/account'}
        className={({ isActive }) =>
          isActive ? classNames(styles.wrapper__item, styles.wrapper__item__active) : styles.wrapper__item
        }
      >
        <UserIcon />

        <p>Кабінет</p>
      </NavLink>
    </div>
  )
}

export default MobileNavBar
