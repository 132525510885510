import React, { FC } from 'react'
import { Button } from 'components'
import { ChatIcon } from 'assets'
import styles from './Chat.module.scss'


interface ChatProps {
  openContacts: () => void; // Define the type for openContacts prop
}
const Chat: FC<ChatProps> = ({ openContacts }) => {
  return (
    <div onClick={openContacts} className={styles.wrapper}>
      <Button className={styles.wrapper__button} LeftIcon={ChatIcon}>
        Чат
      </Button>
    </div>
  )
}

export default Chat
