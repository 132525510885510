import type { FC, KeyboardEvent } from 'react'
import { SearchIcon } from 'assets'
import styles from './Search.module.scss'
import { useAppDispatch, useAppSelector } from 'hooks/useTypedSelector'
import { fetchSearchResults, setQuery } from 'store/features/Search/searchSlice'

const Search: FC = () => {
  const dispatch = useAppDispatch()

  const query = useAppSelector(state => state.search.query)

  const handleSearch = () => {
    if (query) {
      dispatch(fetchSearchResults(query))
      window.location.href = `/app/categories?search=${query}`
      setQuery('')
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <div className='flex items-center w-full max-w-[590px] max-[876px]:p-4'>
      <input
        value={query}
        onChange={e => dispatch(setQuery(e.target.value))}
        onKeyPress={handleKeyPress}
        placeholder='Введіть назву товару або артикул'
        type='text'
        className='flex items-center w-full bg-white pl-4 rounded-tl-lg rounded-bl-lg border-none h-12'
      />
      <button onClick={handleSearch} className='p-3 bg-[#ab0000] border-none max-h-12 rounded-tr rounded-br'>
        <SearchIcon />
      </button>
    </div>
  )
}

export default Search
