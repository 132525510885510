import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api/axiosConfig'
import { TAuth } from './type'
import axios from 'axios'

const initialState: TAuth = {
  data: [],
  loading: false,
  error: null,
}

const getData = async () => {
  const res = await axios.get('https://api.ipify.org/?format=json')

  return res.data.ip
}

export const registrationUser = createAsyncThunk('auth/reguser', async (formData: any) => {
  const ip = await getData()
  if (ip) {
    try {
      const response = await api.post(`/v2/auth/register`, {
        telephone: formData.telephone,
        email: formData.email,
        firstname: formData.name,
        ip: ip,
        device: 'Test',
        password: formData.password,
        password_confirmation: formData.password,
        term: true,
      })

      return response
    } catch (err: any) {
      return err.response
    }
  }
})

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(registrationUser.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(registrationUser.fulfilled, (state, action) => {
        ;(state.loading = false), (state.data = action.payload)

        if (action.payload.data.token) {
          localStorage.setItem('userData', JSON.stringify(action.payload))
        }
      })
      .addCase(registrationUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
  },
})

export { registrationSlice }
