import { useState, type FC } from 'react'
import classNames from 'classnames'

import { useResponsive } from 'hooks'
import { Link, NavLink } from 'react-router-dom'
import { Menu, Search } from 'components'
import { BurgerCloseIcon, BurgerIcon, HeartStrokeIcon, LogoIcon, ShopCartIcon, UserIcon } from 'assets'
import MultiLevelMenu from 'components/molecules/MobileMenu'

import styles from './Header.module.scss'
import { ERoutePaths } from 'router/types'
import { useAppSelector } from 'hooks/useTypedSelector'

const Header: FC = () => {
  // const { watchlistSLice } = useAppSelector(state => state)
  const basketQuality = useAppSelector(state => state?.basketSlice?.data?.data?.length)
  const watchlistQuality = useAppSelector(state => state?.watchlistSLice?.data?.length)
  // const basketQuality = basketSlice.data?.data?.length
  // const watchlistQuality = watchlistSLice.data?.length
  const [isOpen, setOpen] = useState<boolean>(false)

  const closeMobileNav = () => {
    setOpen(false)
  }

  const openMobileNav = () => {
    setOpen(true)
  }

  const { isTablet } = useResponsive()

  return (
    <>
      <header className='center w-full p-4 bg-[#1a1a1a] h-20 relative max-[876px]:fixed max-[876px]:top-0 max-[876px]:left-0 max-[876px]:z-[3]'>
        <div className='w-full max-w-[1200px] gap-8 mx-auto center max-[876px]:justify-between'>
          <Link to='/'>
            <LogoIcon className='min-w-[180px] min-h-[39px]' />
          </Link>

          {isTablet ? (
            isOpen ? (
              <BurgerCloseIcon className='ml-2' onClick={closeMobileNav} />
            ) : (
              <BurgerIcon className='ml-2' onClick={openMobileNav} />
            )
          ) : (
            <>
              <Menu />
              <Search />
              <div className="flex items-center gap-8">
                <NavLink
                  to={ERoutePaths.Saved}
                  className={({ isActive }) =>
                    isActive
                      ? classNames(styles.wrapper__icons_group__item, styles.wrapper__icons_group__item__active)
                      : styles.wrapper__icons_group__item
                  }
                >
                  {watchlistQuality !== 0 && watchlistQuality !== undefined && (
                    <div className='list_quality'>{watchlistQuality}</div>
                  )}{' '}
                  <HeartStrokeIcon />
                  <p>Бажане</p>
                </NavLink>

                <NavLink
                  to={ERoutePaths.Basket}
                  className={({ isActive }) =>
                    isActive
                      ? classNames(styles.wrapper__icons_group__item, styles.wrapper__icons_group__item__active)
                      : styles.wrapper__icons_group__item
                  }
                >
                  {basketQuality !== 0 && basketQuality !== undefined && (
                    <div className='list_quality'>{basketQuality}</div>
                  )}{' '}
                  <ShopCartIcon />
                  <p>Кошик</p>
                </NavLink>

                <NavLink
                  to='/account'
                  role='button'
                  className={({ isActive }) =>
                    isActive
                      ? classNames(styles.wrapper__icons_group__item, styles.wrapper__icons_group__item__active)
                      : styles.wrapper__icons_group__item
                  }
                >
                  <UserIcon />

                  <p>Увійти</p>
                </NavLink>
              </div>
            </>
          )}
        </div>
      </header>
      <MultiLevelMenu isOpen={isOpen} />
    </>
  )
}

export default Header
