import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api/axiosConfig'
// import { TSlice } from '../type'

const initialState: any = {
  data: [],
  loading: false,
  error: null,
  countries: [],
  cities: [],
  warehaouses: [],
  streets: [],
  payments: [],
  loadingAddress: false,
}

export const createOrder = createAsyncThunk('order', async (orderData: any) => {
  try {
    const response = await api.post(`/v3/order`, orderData)

    return response
  } catch (err) {
    throw Error('Failed to make new order')
  }
})
interface UpdateOrderPayload {
  order_id: number;
  data: any; 
}
export const updateOrder: any = createAsyncThunk('order/updateOrder', async (payload: UpdateOrderPayload) => {
  console.log(payload);
  try {
    const response = await api.put(`/v1/order/${payload?.order_id}`, payload?.data)
    return response
  } catch (err) {
    console.error(err);
    
  }
})

export const fetchCountries = createAsyncThunk('countries', async () => {
  try {
    const response = await api.get('/v2/countries?filter_exclude_default=1', {})

    return response.data
  } catch (err) {
    throw Error('Failed to fetch countries')
  }
})

export const fetchCities = createAsyncThunk(
  'cities',
  async ({ filterName, page = 1, pageSize = 10 }: { filterName: string; page?: number; pageSize?: number }) => {
    try {
      const response = await api.get('/v2/localisation/cities', {
        params: {
          filter_name: filterName,
          page,
          pageSize,
        },
      })
      return response.data
    } catch (err) {
      throw Error('Failed to fetch cities')
    }
  }
)

export const fetchWarehouses = createAsyncThunk(
  'warehouses',
  async ({ cityRef, filterName }: { cityRef: any; filterName: any }) => {
    try {
      const response = await api.get(`/v1/localisation/warehouses?filter_name=${filterName}&CityRef=${cityRef}`)

      return response.data
    } catch (err) {
      throw Error('Failed to fetch warehouse')
    }
  }
)

export const fetchStreets = createAsyncThunk(
  'streets',
  async ({ cityRef, filterName }: { cityRef: any; filterName: any }) => {
    try {
      const response = await api.get(`/v1/localisation/streets`, {
        params: {
          city_ref: cityRef,
          filter_name: filterName,
        },
      })

      return response.data
    } catch (err) {
      throw Error('Failed to fetch warehouse')
    }
  }
)

export const fetchPaymets = createAsyncThunk('payments', async ({ shipingCode, customTtnShow }: { shipingCode: string; customTtnShow: boolean }) => {

  try {
    const response = await api.get(
      `/v2/orders/payment-methods?custom_ttn=${customTtnShow ? 1 : 0}&shipping_code=novaposhta.${shipingCode}`
    )
    return response
  } catch (err) {
    throw Error('Failed to fetch paymentys')
  }
})

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createOrder.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      // .addCase(updateOrder.pending, (state, action) => {
      //   state.loading = false
      //   state.error = action.error.message
      // })
      // .addCase(updateOrder.fulfilled, (state, action) => {
      //   state.loading = false
      //   state.error = action.error.message
      // })
      // .addCase(updateOrder.rejected, (state, action) => {
      //   state.loading = false
      //   state.error = action.error.message
      // })
      .addCase(fetchCountries.pending, state => {
        state.loadingAddress = true
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries = action.payload
        state.loadingAddress = false
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload
      })
      .addCase(fetchWarehouses.fulfilled, (state, action) => {
        state.warehaouses = action.payload
      })
      .addCase(fetchStreets.fulfilled, (state, action) => {
        state.streets = action.payload
      })
      .addCase(fetchPaymets.fulfilled, (state, action) => {
        state.payments = action.payload
      })
  },
})

export { orderSlice }
