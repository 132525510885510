import { PROD, LOCAL, STAGE } from './env';

const getEnvVars = () => {
    const API_ENV = process.env.REACT_APP_API_ENV;

    const env = API_ENV ;

    switch (env) {
        case 'local':
            return STAGE;
        case 'stage':
            return STAGE;
        default:
            return PROD;
    }
};

export default getEnvVars;
